
export const apiRequest = {
  base: process.env.REACT_APP_BASE_URL,

  baseForInitialIds: process.env.REACT_APP_BASE_INITIAL_ID,

  validationLocation: 'https://zip.getziptastic.com/v2/US',

initialIds:               '/agia-appcontent-service/api/v1/client-attribute',
login:                    '/agia-appauth-service/api/v1/account/login',
loginAttempts:            '/o/authentication/v1.0/loginFail',
getContent:               '/o/webcontent/v1.0/getContentByName',
getContents:              '/agia-appcontent-service/api/v1/contents/client',  
verifyPolicyNumber:       '/agia-appauth-service/api/v1/account/policy-verification',
verifyPersonalInfo:       '/agia-appauth-service/api/v1/account/name-client-verification',
verifySignInCreds:        '/agia-appauth-service/api/v1/account/registration',
forgotUsername:           '/agia-appauth-service/api/v1/account/username',
resetPassword:            '/agia-appauth-service/api/v1/account/reset-password',
forgotPassword1:          '/agia-appauth-service/api/v1/account/password',
forgotPassword2:          '/agia-appauth-service/api/v1/account/password',
changePassword:           '/agia-appauth-service/api/v1/account/password',
verifyCaptcha:            '/agia-appauth-service/api/v1/security/bot',
getProfile:               '/myaccount-core-service/api/v1/profile',
updateMyProfile:          '/agia-appauth-service/api/v1/user/profile',
getSecurityQuestions:     '/agia-appauth-service/api/v1/user/getSecurityQuestions',
updateSecurityDetails:    '/agia-appauth-service/api/v1/user/updateSecurityDetails',
getPaymentMethods:        '/myaccount-core-service/api/v1/payment/paymentMethods',
getStatesList:            '/agia-appcontent-service/api/v1/states?countryId=19',
addPaymentMethod:         '/myaccount-core-service/api/v1/payment/paymentMethod',
updatePaymentMethod:      '/myaccount-core-service/api/v1/payment/paymentMethod',
deletePaymentMethod:      '/myaccount-core-service/api/v1/payment/paymentMethodDelete',
getProducts:              '/myaccount-core-service/api/v1/payment',
dashboard:                '/myaccount-core-service/api/v1/homepage',
getPayNowHomePage:        '/myaccount-core-service/api/v1/homepage/paynow',
logout:                   '/agia-appauth-service/api/v1/account/logout',
getProductsMain:          '/myaccount-core-service/api/v1/product/summary',
getProductDetails:        '/myaccount-core-service/api/v1/product/details',
searchClient:             '/agia-appauth-service/api/v1/customerService/{search}/{value}/user-id/{userId}',
impersonateUser:          '/agia-appauth-service/api/v1/customerService/impersonateUser/company-id/{companyId}/user-id/{userId}',
deleteUser:               '/agia-appauth-service/api/v1/customerService/deleteUser/company-id/{companyId}/username/{userId}',
viewDocument:             '/myaccount-core-service/api/v1/product/document/{documentId}/documentSource/{documentType}/documentCategory/{documentCategory}',
unsubscribe:              '/agia-appauth-service/api/v1/user/subscribe/true/userId',
resubscribe:              '/agia-appauth-service/api/v1/user/subscribe/false/userId',
insproservice:            '/myaccount-core-service/api/v1/utility/serviceStatus',
registerMigration:        '/o/migration/v1.0/',  
getPayNow:                '/myaccount-core-service/api/v1/payment/paynow',  
changeFrequencyAutopay:   '/myaccount-core-service/api/v1/payment/autoPaymentPreference',
submitPayNow:             '/myaccount-core-service/api/v1/payment/oneTimePayment',
getPaymentInfo:           '/myaccount-core-service/api/v1/payment/paymentAmountBreakdown',
getUpdatedFrequency:      '/myaccount-core-service/api/v1/payment/autoPaymentPreference',
getPagesTitle:            '/agia-appcontent-service/api/v1/contentStructure',
updateLoginInformations:  '/agia-appauth-service/api/v1/account/updateUserInformation'
};

export const byPassCsUserId = [
  apiRequest.base + apiRequest.getContents,
];

export const SETNRTY_CONFIG = { 
  sentryDSN: process.env.REACT_APP_SENTRY_DSN, 
  sentryENV: process.env.REACT_APP_ENV, 
} 